import React, { useEffect, useState } from "react";
import "./style.css";
import { Layout } from "../../layout";
import { CardWrapper, HomeTable } from "../../components";
import {
  Grid,
  Button,
  Typography,
  Menu,
  MenuItem,
  FormControl,
  ListItemText,
  Box,
  Checkbox,
  Modal,
  Select,
} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { CiFilter } from "react-icons/ci";
import CheckBox from "../../components/checkbox/index.jsx";
import {
  fetchCompanies,
  addNewCompany,
  updateCompany,
  setFilteredCompanies,
  clearFilteredCompanies,
} from "../../redux/actions/company.js";
import { useNavigate } from "react-router-dom";
import currency_symbols from "../../utils/currency.js";
import { industries } from "../../utils/industries";
import SearchIcon from "../../assets/search-icon.svg";
import { fetchUserById, fetchTeamMembers } from "../../redux/actions/user.js";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { handlInfo } from "../../utils/utilFunctions.js";
import SnackBar from "../../components/snackBar";
import { FormControlLabel } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModel, setOpenModel] = useState(false);
  const [industry, setIndustry] = useState("");
  const [currency, setCurrency] = useState("");
  const [isService, setIsService] = useState(false);
  const [sendCompanyId, setSendCompanyId] = useState("");
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState({
    companyName: "",
    code: "",
  });

  const [newMembers, setNewMembers] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [anchorFilter, setAnchorFilter] = useState(false);

  const [filters, setFilters] = useState({
    service: false,
    NAICS: false,
    // date: false,
  });

  const [members, setMembers] = useState([]);

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    filteredCompanies,
    comapnies,
    compLoading,
    selectedCompany,
    editCompLoading,
  } = useSelector((state) => state.companyReducer);

  const { user, userEmail } = useSelector((state) => state.userReducer);

  const fetchMembers = async () => {
    const membersTeam = await dispatch(fetchTeamMembers());
    setMembers(membersTeam);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {}, [selectedCompany]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let PStatus = localStorage.getItem("paymentStatus");
    let cpcp_subscribed = localStorage.getItem("cpcp_subscribed");

    if (
      (!token || token === "undefined") &&
      (!PStatus || PStatus !== "true") &&
      (!cpcp_subscribed || cpcp_subscribed !== "true")
    ) {
      navigate("/");
    } else if (PStatus === "false" && cpcp_subscribed === "false") {
      navigate("/subscription");
    } else {
      if (!Object.keys(user).length) {
        dispatch(fetchUserById({ userId: localStorage.getItem("id") }));
      }
      dispatch(
        fetchCompanies({ userId: localStorage.getItem("id"), userEmail })
      );
    }
  }, [navigate]);

  const filterData = (val) => {
    let filteredResults = [];

    switch (val) {
      case "service":
        filteredResults = comapnies
          .sort((a, b) => a.isService - b.isService)
          .reverse();

        break;

      case "NAICS":
        filteredResults = comapnies.sort(
          (a, b) => parseInt(a.NAICScode) - parseInt(b.NAICScode)
        );

        break;

      // case "date":
      //   break;

      default:
        break;
    }

    dispatch(setFilteredCompanies(filteredResults));
  };

  const handleSubmit = async () => {
    const payload = {
      companyName: state.companyName,
      NAICScode: state.code,
      userId: localStorage.getItem("id"),
      currency: currency,
      industry: industry,
      sharedWithEmail: newMembers,
      isService,
    };
    const respo = await dispatch(addNewCompany(payload));
    if (respo) {
      handleModelClose();
      setNewMembers([]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleEditValues = async () => {
    let companyId = sendCompanyId;

    const payload = {
      companyName: state.companyName,
      currency: currency ? currency : selectedCompany.currency,
      NAICScode: state.code ? state.code : selectedCompany.NAICScode,
      industry: industry ? industry : selectedCompany.industry,
      sharedWithEmail: newMembers,
      isService,
    };

    const respo = await dispatch(updateCompany(payload, companyId));
    if (respo) handleModelClose();

    if (selectedCompany?._id === companyId) {
      dispatch({
        type: "UPDATE_SELECTED_COMP_DETAIL",
        payload: { ...selectedCompany, ...payload },
      });
    }
  };

  const handleModelClose = () => {
    setState({
      companyName: "",
      code: "",
    });
    setOpenModel(false);
    setNewMembers([]);
  };

  const handleOpen = () => {
    setOpenModel(true);
  };

  const handleOpenModal = () => {
    setEdit(false);
    handleOpen();
  };

  const handleFilterClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const hanldeFilters = (val) => {
    let newFilter = { ...filters };

    Object.keys(filters).forEach((f) => {
      if (f === val) {
        newFilter = { ...newFilter, [f]: true };
      } else {
        newFilter = { ...newFilter, [f]: false };
      }
    });

    filterData(val);

    setFilters(newFilter);
  };

  const handleNoMember = () => {
    if (members && members.length) {
      for (let i = 0; i < members?.length; i++) {
        if (members[i].isSignedUp) {
          i = members.length;
          return false;
        }
        if (i < members.length - 1) {
          return true;
        }
      }
    }
    return true;
  };

  const handleNewMemberToggle = (item) => {
    let dummySelected = [...newMembers];

    if (newMembers.includes(item)) {
      dummySelected = dummySelected.filter((ds) => ds !== item);
    } else {
      dummySelected = [...dummySelected, item];
    }

    setNewMembers(dummySelected);
  };

  return (
    <React.Fragment>
      <Layout title="Home">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              height: "calc(100vh - 208px)",
            }}
            className="hide_sideBar home_main"
          >
            {compLoading ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 30,
                      height: 50,
                    }}
                  >
                    <Typography className="home_my_companies">
                      My Companies{" "}
                      <InfoOutlinedIcon
                        onClick={() => handlInfo("company")}
                        className="infor_icon"
                      />
                    </Typography>
                    <Button
                      onClick={handleOpenModal}
                      className="home_add_company"
                    >
                      <span style={{ fontSize: 30, marginRight: 10 }}>+</span>{" "}
                      Add Company
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      height: "calc(100% - 80px)",
                    }}
                  >
                    <CardWrapper border="1px solid #E7E7E7">
                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          marginBottom: 25,
                        }}
                      >
                        <div>
                          <Button
                            onClick={handleFilterClick}
                            className="home_filter"
                          >
                            {/* <span style={{ fontSize: 30, marginRight: 10 }}>
                            +
                          </span>{" "} */}
                            <CiFilter className="filter_icon" />
                            Filter
                          </Button>
                          <Menu
                            anchorEl={anchorFilter}
                            open={Boolean(anchorFilter)}
                            onClose={() => setAnchorFilter(false)}
                          >
                            <MenuItem>
                              <FormControlLabel
                                control={
                                  <div style={{ padding: "5px" }}>
                                    <CheckBox
                                      checked={filters.service}
                                      onClick={() => hanldeFilters("service")}
                                    />
                                  </div>
                                }
                                label="Service Company"
                              />
                            </MenuItem>
                            <MenuItem>
                              <FormControlLabel
                                control={
                                  <div style={{ padding: "5px" }}>
                                    <CheckBox
                                      checked={filters.NAICS}
                                      onClick={() => hanldeFilters("NAICS")}
                                    />
                                  </div>
                                }
                                label="NAICS code"
                              />
                            </MenuItem>
                          </Menu>
                        </div>
                      </Grid>
                      <HomeTable
                        comapnies={comapnies}
                        selectedCompany={selectedCompany}
                        handleOpen={handleOpen}
                        setEdit={setEdit}
                        setState={setState}
                        filteredCompanies={filteredCompanies}
                        setSendCompanyId={setSendCompanyId}
                        members={members}
                        handleNoMember={handleNoMember}
                        setNewMembers={setNewMembers}
                      />
                    </CardWrapper>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </CardWrapper>
      </Layout>

      <Modal
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Enter Details</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Company Name
              </Typography>
              {!edit ? (
                <div style={{ display: "flex" }}>
                  <Checkbox
                    style={{ padding: "0px" }}
                    onChange={(e) => setIsService(e.target.checked)}
                  />{" "}
                  <p style={{ marginTop: "10px" }}>Service </p>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <Checkbox
                    style={{ padding: "0px" }}
                    defaultChecked={state.isService}
                    onChange={(e) => setIsService(e.target.checked)}
                  />{" "}
                  <p style={{ marginTop: "10px" }}>Service </p>
                </div>
              )}
            </div>

            <input
              className="customize-input1"
              placeholder="Enter Company Name"
              color="white"
              name="companyName"
              type="text"
              value={state.companyName}
              onChange={handleChange}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                NAICS Code
              </Typography>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Currency
              </Typography>
            </Box>

            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <input
                className="customize-input1"
                placeholder="Enter Code here i.e 346242"
                color="white"
                name="code"
                type="text"
                value={state.code}
                onChange={handleChange}
              />

              <FormControl className="currencies-form">
                <Select
                  defaultValue={state.currency}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                  sx={{ border: "1px solid", borderRadius: "22px" }}
                >
                  {Object.keys(currency_symbols).map((keys, index) => {
                    return (
                      <MenuItem key={index} value={keys}>
                        {currency_symbols[keys]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <a
                href="https://census.gov/naics"
                target="blank"
                className="code-link"
              >
                Find NAICS
              </a>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                Industry
              </Typography>
            </Box>
            <FormControl className="industry-form">
              <Select
                defaultValue={state.industry}
                label="Industry"
                onChange={(e) => {
                  setIndustry(e.target.value);
                }}
                sx={{ border: "1px solid", borderRadius: "22px" }}
              >
                {Object.keys(industries).map((keys, index) => {
                  return (
                    <MenuItem key={index} value={keys}>
                      {industries[keys]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                Members
              </Typography>
            </Box>
            <FormControl className="industry-form">
              <Select
                // defaultValue={state.industry}
                label="Members"
                // onChange={(e) => {
                //   setIndustry(e.target.value);
                // }}
                sx={{ border: "1px solid", borderRadius: "22px" }}
                multiple
                value={newMembers}
                defaultValue={newMembers}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null, // Required to disable default anchoring
                }}
              >
                {handleNoMember() ? (
                  <p
                    style={{
                      padding: "10px",
                      color: "gray",
                    }}
                  >
                    No member added yet
                  </p>
                ) : (
                  members?.map((member) => {
                    console.log("memberasda", member);
                    if (member.isSignedUp)
                      return (
                        <MenuItem
                          onClick={() =>
                            handleNewMemberToggle(member.memberEmail)
                          }
                        >
                          <CheckBox
                            style={{ backgroundColor: "white" }}
                            checked={newMembers.includes(member.memberEmail)}
                          />
                          <ListItemText primary={member.memberEmail} />
                        </MenuItem>
                      );
                  })
                )}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="login-btn"
                disabled={editCompLoading}
                onClick={!edit ? handleSubmit : handleEditValues}
              >
                Submit
                {editCompLoading ? (
                  <CircularProgress
                    color="inherit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5%",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <SnackBar
        setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
    </React.Fragment>
  );
};
export default Home;
