const initialState = {
  user: {},
  userLoading: false,
  userEmail: "",
  token: "",
  id: "",
  profileName: "",
  logo: "",
  paymentStatus: "",
  cpcp_subscribed: "",
  error: "",
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case "STOP_LOADING_USER":
      return { ...state, userLoading: false };

    case "LOADING_USER":
      return { ...state, userLoading: true };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        userLoading: false,
        userEmail: action.payload.user.email,
        token: action.payload.token,
        id: action.payload.user._id,
        paymentStatus: action.payload.user.payment_status,
        cpcp_subscribed: action.payload.user.cpcp_subscribed,
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        userLoading: false,
        user: action.payload.data,
        userEmail: action.payload.data.email,
        token: action.payload.token,
        id: action.payload.data._id,
        profileName: action.payload.data.profileName,
        logo: action.payload.data.logo,
        paymentStatus: action.payload.data.payment_status,
        cpcp_subscribed: action.payload.data.cpcp_subscribed,
      };

    case "LOGOUT":
      return {
        ...state,
        ...initialState,
      };

    case "ERROR_LOGIN":
      return {
        ...state,
        error: action?.payload?.message,
        userLoading: false,
      };

    case "INIT_STATES":
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default userReducers;
