import React, { useState, useEffect } from "react";
import { Layout } from "../../layout";
import "./style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";
import { CardWrapper } from "../../components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubscription,
  updateProfile,
  updateProfileName,
} from "../../redux/actions/user";
import {
  fetchUserById,
  fetchTeamMembers,
  deleteMember,
  addTeamMember,
} from "../../redux/actions/user";
import SnackBar from "../../components/snackBar";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { isValidEmail } from "../../utils/utilFunctions";
import { handlInfo } from "../../utils/utilFunctions";
import { clearSnack } from "../../utils/utilFunctions";

const TeamManagment = () => {
  const dispatch = useDispatch();

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const [memberEmail, setMemberEmail] = useState();
  const [members, setMembers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackType, setSnackType] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteIndex, setDeleteIndex] = useState();

  const fetchTeam = async () => {
    const membersTeam = await dispatch(fetchTeamMembers());
    setMembers(membersTeam);
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  //   mwmilan@hotmail.com

  const handleDelete = async (member, i) => {
    setDeleteLoading(true);
    setDeleteIndex(i);

    const data = {
      memberEmail: member.memberEmail,
      ownerId: localStorage.getItem("id"),
    };

    await dispatch(deleteMember(data));
    fetchTeam();
    setDeleteLoading(true);

    // axios
    //   .post(`${BASE_URL}/team/delete`, data)
    //   .then((res) => {
    //     setMembers(res.data.data);

    //     setOpenSnackBar(true);
    //     setSnackType("success");
    //     setMessage(res.data.message);
    //     setDeleteLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err.response.data.message);
    //     setOpenSnackBar(true);
    //     setSnackType("");
    //     setMessage(err.response.data.message);
    //     setDeleteLoading(false);
    //   });
  };

  const handleAddMember = async () => {
    setAddLoading(true);
    if (!memberEmail) {
      //   setOpenSnackBar(true);
      //   setSnackType("");
      //   setMessage("Please enter Member Email");
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter Member Email",
        },
      });
      clearSnack(dispatch);
      setAddLoading(false);
      return;
    }
    if (!isValidEmail(memberEmail)) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter a valid Email",
        },
      });
      clearSnack(dispatch);
      //   setOpenSnackBar(true);
      //   setSnackType("");
      //   setMessage("Please enter a valid Email");
      setAddLoading(false);
      return;
    }

    const data = {
      memberEmail,
      ownerId: localStorage.getItem("id"),
    };

    await dispatch(addTeamMember(data));
    fetchTeam();
    setAddLoading(false);

    // axios
    //   .post(`${BASE_URL}/team/add`, data)
    //   .then((res) => {
    //     setMembers(res.data.data);

    //     setOpenSnackBar(true);
    //     setSnackType("success");
    //     setMessage(res.data.message);
    //     console.log("res after adding team  member", res);
    //     setAddLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err.response.data.message);
    //     setOpenSnackBar(true);
    //     setSnackType("");
    //     setMessage(err.response.data.message);

    //     console.log("errro", err);
    //     setAddLoading(false);
    //   });
  };

  return (
    <CardWrapper>
      <Typography className="hidden_cash_subTitle">
        Team Management{" "}
        <InfoOutlinedIcon
          onClick={() => handlInfo("teamManagement")}
          className="infor_icon"
        />
      </Typography>
      <Box style={{ height: 30, width: "100%" }} />
      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          style={{ display: "grid", justifyContent: "stretch" }}
        >
          <Typography style={{ fontSize: 16, marginBottom: 12 }}>
            Email
          </Typography>
          <input
            onChange={(e) => setMemberEmail(e.target.value)}
            className="companydetail_inputs"
            placeholder="Enter Email To Add"
          />
        </Grid>
        <Box style={{ height: 25, width: "100%" }} />
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="hidden_cash_subTitle">Members</Typography>
          <Button
            onClick={handleAddMember}
            disabled={addLoading}
            className="add_member_btn"
          >
            <AddCircleOutlineIcon style={{ fontSize: 18, marginRight: 10 }} />{" "}
            Add Member
          </Button>
        </Grid>
        <Box style={{ height: 30, width: "100%" }} />
        <CardWrapper className="add_team_box" border="1px solid #E7E7E7">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F2F2F5",
              padding: "15px 20px",
              borderRadius: "100px",
            }}
          >
            <Typography>Email</Typography>
            <Typography>Action</Typography>
          </Grid>
          {!members || !members.length ? (
            <>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "55px 0px",
                }}
              >
                No data found
              </Typography>
              <Box style={{ height: 50, width: "100%" }} />
            </>
          ) : (
            <>
              <Grid
                container
                xs={12}
                style={{
                  maxHeight: "200px",
                  minHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                {members.map((member, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // backgroundColor: "#F2F2F5",
                      padding: "0 20px",
                      borderRadius: "100px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        paddingTop: "25px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {member.isSignedUp ? (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              User signed up
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <CheckCircleIcon
                            style={{
                              color: "#00a0ff",
                              fontSize: "20px",
                              paddingRight: "5px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Waiting for user to sign up
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <QueryBuilderIcon
                            style={{
                              color: "#ffa000",
                              fontSize: "20px",
                              paddingRight: "5px",
                            }}
                          />
                        </Tooltip>
                      )}
                      {member.memberEmail}
                    </Typography>{" "}
                    <Button style={{ height: "75px" }}>
                      {deleteLoading && deleteIndex == i ? (
                        <CircularProgress
                          color="inherit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "5%",
                          }}
                        />
                      ) : (
                        <DeleteOutlineIcon
                          onClick={() => handleDelete(member, i)}
                          style={{ color: "#d81818" }}
                        />
                      )}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </CardWrapper>
        <Box style={{ height: 25, width: "100%" }} />
        <Grid
          container
          xs={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button className="save_btn">Back</Button>
        </Grid>
      </Grid>
      <SnackBar
        setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
    </CardWrapper>
  );
};

export default TeamManagment;
