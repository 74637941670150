import React, { useState, useEffect } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ThomsonLogo from "../../assets/thomson-logo.svg";
import { VscFeedback } from "react-icons/vsc";
import { HiOutlineHome } from "react-icons/hi2";
import { FaRegNoteSticky } from "react-icons/fa6";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { SiMoneygram } from "react-icons/si";
import { TbHome2 } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { TbTag } from "react-icons/tb";
import { TiCloudStorageOutline } from "react-icons/ti";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { useSelector } from "react-redux";
import SnackBar from "../snackBar";
import { FaStethoscope } from "react-icons/fa6";
import "./style.css";
import logo from "../../images/logo.png";

const Sidebar = () => {
  const { user } = useSelector((state) => state.userReducer);
  const { graphLoading } = useSelector((state) => state.pdfGraphReducer);
  const { company } = useSelector((state) => state.companyDetailReducer);

  const { selectedCompany } = useSelector((state) => state.companyReducer);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let token = localStorage.getItem("token");
    let PStatus = localStorage.getItem("paymentStatus");
    let cpcp_subscribed = localStorage.getItem("cpcp_subscribed");

    if (
      (!token || token === "undefined") &&
      (!PStatus || PStatus !== "true") &&
      (!cpcp_subscribed || cpcp_subscribed !== "true")
    ) {
      navigate("/");
    } else if (PStatus === "false" && cpcp_subscribed === "false") {
      navigate("/subscription");
    } else if (!Object.keys(company).length) {
      if (location.pathname !== "/company-detail") navigate("/home");
    }
  }, [navigate]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const sidebarTabs = [
    {
      icon: HiOutlineHome,
      label: "Home",
      route: "/home",
      disabled: false,
    },
    {
      icon: PauseCircleOutlineIcon,
      label: "Start With End In Mind",
      route: "/start-with-end-in-mind",
      disabled: graphLoading,
    },
    {
      icon: FaRegNoteSticky,
      label: "Financial Data",
      route: "/financial-data",
      disabled: false,
    },
    {
      icon: HiOutlineCurrencyDollar,
      label: "Hidden Cash",
      route: "/hidden-cash",
      disabled: false,
    },
    {
      icon: SiMoneygram,
      label: "Fast Money Formula",
      route: "/fast-money-formula",
      disabled: false,
    },
    {
      icon: TbHome2,
      label: "Home Run",
      route: "/home-run",
      disabled: false,
    },
    {
      icon: IoBagOutline,
      label: "Simple Valuation",
      route: "/simple-valuation",
      disabled: false,
    },
    {
      icon: FaStethoscope,
      label: "Financial Doctor",
      route: "/financial-doctor",
      disabled: false,
    },
    {
      icon: TiCloudStorageOutline,
      label: "Create PDF",
      route: "/pdf",
      disabled: graphLoading,
    },
  ];

  const handleNavigate = (route) => {
    if (
      Object.keys(selectedCompany).length ||
      route === "/home" ||
      route === "/contact-us"
    )
      return navigate(route);

    setOpen(true);
    setMessage("Please Select Company First");
  };
  return (
    <React.Fragment>
      <Grid container xs={12} className="sidebar_main">
        <Box className="sidebar_ThomsonLogo" style={{ width: "100%" }}>
          <img
            src={user.logo ? user.logo : logo}
            alt="Logo"
            className="sidebar_ThomsonLogo"
            onClick={() => handleNavigate("/home")}
          />
          {sidebarTabs?.map((tab, index) => (
            <Box
              className={`sidebar_tab ${
                window.location.pathname === tab?.route && "sidebar_tab_active"
              } `}
              key={index}
              onClick={() => {
                // if (tab.label === "Financial Doctor") {
                //   setOpen(true);
                //   setMessage("Coming soon!");
                // }
                if (!tab.disabled) handleNavigate(tab?.route);
              }}
            >
              <tab.icon style={{ marginRight: 10, fontSize: 19 }} />
              <div>{tab?.label}</div>
              {tab.route === "/pdf" && tab.disabled ? (
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}
            </Box>
          ))}
          <Box
            className="contact_us"
            style={{
              marginTop: "80%",
              position: "fixed",
              bottom: 10,
              left: 10,
              textAlign: "center",
            }}
          >
            <VscFeedback style={{ marginRight: 10, fontSize: 25 }} />
            <div onClick={() => handleNavigate("/contact-us")}>Contact us</div>
          </Box>
        </Box>
      </Grid>
      <SnackBar
        setOpen={setOpen}
        open={open}
        snackType={"error"}
        msg={message}
      />
    </React.Fragment>
  );
};
export default Sidebar;
