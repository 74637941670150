import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import html2canvas from "html2canvas";

import { fetchStartWithEndNotes } from "../../redux/actions/notes";
import currency_symbols from "../../utils/currency";
import CurrencyInput from "../../components/CurrencyInput";
import {
  addStartWithEnd,
  fetchLatestSimpleVal,
  fetchStartWithEnd,
} from "../../redux/actions/startWithEnd";
import SnackBar from "../../components/snackBar";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import {
  OurPlan,
  OurPerformace,
  CalculateTrans,
  SavingPlan,
  EstimateValue,
} from "../../components/startWithEndComps";
import {
  handlInfo,
  removeComma,
  roundUpToNearestThousand,
} from "../../utils/utilFunctions";
import formateDate from "../../utils/formateDate";
import { pushTransferableValue } from "../../redux/actions/startWithEnd";
import { updatePDFData } from "../../redux/actions/pdfGraph";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const StartWithEndInMind = () => {
  const { startWithEndNotes } = useSelector((state) => state.notesReducer);

  console.log("startWithEndNotes", startWithEndNotes);

  const dispatch = useDispatch();

  const { openSnackbar, snackType, message } = useSelector(
    (state) => state.snackbarReducer
  );

  const { user } = useSelector((state) => state.userReducer);

  const { company } = useSelector((state) => state.companyDetailReducer);

  const startWithEndNotesRef = useRef(null);

  const ourPerPlanRef = useRef(null)
  const calculateTranRef = useRef(null);
  const estimateValSavingPlanRef = useRef(null)
  const tvCapTableRef = useRef(null);

  const [dream, setDream] = useState("");
  const [needInFuture, setNeedInFuture] = useState("");
  const [yearsUntilExit, setYearsUntilExit] = useState("");
  const [priorSaving, setPriorSaving] = useState("");
  const [transferableValues, setTransferableValues] = useState([]);
  const [tvGoal, setTvGoal] = useState();
  const [currSavingGoal, setCurrSavingGoal] = useState();
  const [latestEquity, setLatestEquity] = useState();
  const [firstEquity, setFirstEquity] = useState();
  const [monthlyGoal, setMonthlyGoal] = useState();
  const [tvCap, setTvCap] = useState();
  const [tvCapDate, setTvCapDate] = useState();
  const [isAddTv, setIsAddTv] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [isGraphUpdate, setIsGraphUpdate] = useState("");

  useEffect(() => {
    fetchStartWithEndData();
  }, []);

  const fetchStartWithEndData = async () => {
    const res = await dispatch(fetchStartWithEnd(company._id));

    const { data } = res.data;

    setDream(data.dream);
    setNeedInFuture(data.needInFuture);
    setPriorSaving(data.priorSaving);
    setYearsUntilExit(data.yearsUntilExit);
    setTransferableValues(data.transferableValues);
    setCreatedAt(formateDate(data.created_at));

    const dumTv = parseInt(removeComma(data.needInFuture)) * 20;
    const dumCurrSavingGoal = dumTv - parseInt(removeComma(data.priorSaving));

    handleTransferableValueGoal(parseInt(removeComma(data.needInFuture)));
    handleCurrSavingGoal(dumTv, parseInt(removeComma(data.priorSaving)));
    handleMonthlyGoal(dumCurrSavingGoal, parseInt(data.yearsUntilExit));
  };

  useEffect(() => {
    handleLatestSimpleVal();

    if (Object.keys(company).length) {
      const payload = {
        companyId: company._id,
        page: "StartWithEnd",
      };
      dispatch(fetchStartWithEndNotes(payload));
    }
  }, [company]);

  const handleLatestSimpleVal = async () => {
    const result = await dispatch(fetchLatestSimpleVal(company._id));
    console.log("resultresult", result);
    setLatestEquity(result.data.data.equityVal);
    setFirstEquity(result.data.data.firstEquityVal);
  };

  const conversionThousandToK = (amount) => {
    if (amount >= 1000) {
      return (
        currency_symbols[company.currency] + (amount / 1000).toFixed(0) + "K"
      ); // Round to 1 decimal place
    }
    return amount; // Return the original value if less than 1000
  };

  const formateWithCurrency = (amount) => {
    if (!amount) return "$0";
    const dumAmount = parseInt(amount.toFixed(0));
    return `${currency_symbols[company.currency]}${dumAmount.toLocaleString()}`;
  };

  const handleAddTv = () => {
    setIsAddTv(!isAddTv);
  };

  const handleSave = () => {
    if (!dream || !needInFuture || !yearsUntilExit || !priorSaving) {
      return dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Required all values",
        },
      });
    }

    setIsGraphUpdate((prev) => !prev);
    const payload = {
      companyId: company._id,
      userId: user._id,
      dream,
      needInFuture,
      yearsUntilExit,
      priorSaving,
    };

    dispatch(addStartWithEnd(payload));
  };

  const handleTransferableValueGoal = (needInFuture) => {
    setTvGoal(parseInt(removeComma(needInFuture)) * 20);
  };

  const handleCurrSavingGoal = (tvGoal, priorSaving) => {
    setCurrSavingGoal(parseInt(tvGoal) - parseInt(removeComma(priorSaving)));
  };

  const handleMonthlyGoal = (currSavingGoal, yearsUntilExit) => {
    const dumCurrSavingGoal = removeComma(currSavingGoal);
    if (currSavingGoal && yearsUntilExit) {
      console.log(parseInt(dumCurrSavingGoal) / (yearsUntilExit * 12));

      setMonthlyGoal(
        roundUpToNearestThousand(
          parseInt(dumCurrSavingGoal) / (yearsUntilExit * 12)
        ).toFixed(0)
      );
    }
  };

  const handleTvCapInput = () => {
    if (!tvCap || !tvCapDate) {
      return dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "TV and Date required!",
        },
      });
    }

    setTransferableValues([
      ...transferableValues,
      { date: formateDate(tvCapDate), amount: tvCap },
    ]);

    const payload = {
      date: tvCapDate,
      amount: tvCap,
    };

    dispatch(pushTransferableValue(company._id, payload));
    setTvCap();
    setTvCapDate();
    setIsAddTv(false);
  };

  const handleShowDetails = () => {
    setIsShowDetail(!isShowDetail);
    setIsGraphUpdate((prev) => !prev);
  };

  useEffect(()=> {
    savePDF()
  },[isGraphUpdate])

  const savePDF = () => {
    setTimeout(() => {
      saveSWEMPDF();
    }, 1500);
  }


  const saveSWEMPDF = async () => {
    try {
      

    dispatch({
      type: "GRAPH_LOADING",
    });
    if (!ourPerPlanRef.current) {
      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
      return;
    }

    const ourPlanPerCanvas = await html2canvas(ourPerPlanRef.current)
    const ourPlanPer64Image = await ourPlanPerCanvas.toDataURL("image/png")
    
    const calculateTranCanvas = await html2canvas(calculateTranRef.current)
    const calculateTran64Image = await calculateTranCanvas.toDataURL("image/png")

    const estimateValSavingPlanCanvas = await html2canvas(estimateValSavingPlanRef.current)
    const estimateValSavingPlan64Image = await estimateValSavingPlanCanvas.toDataURL("image/png")

    const startWithEndNotesCanvas = await html2canvas(startWithEndNotesRef.current)
    const startWithEndNotes64Image = await startWithEndNotesCanvas.toDataURL("image/png")

    const payload = {
      notes:{
        estimateValSavingPlan:startWithEndNotes64Image
      },
      images: {
        ourPerPlan : ourPlanPer64Image,
        calculateTran: calculateTran64Image,
        estimateValSavingPlan: estimateValSavingPlan64Image,
      }
    }

    dispatch(updatePDFData(payload));
    dispatch({
      type: "STOP_GRAPH_LOADING",
    });
  } catch (error) {
      console.log('error', error)
  }
  }


  const handleSaleOfBusiness = (latestEquity, tvCap, priorSaving) => {

    const dumLatestEquity = latestEquity? parseInt(latestEquity):0
    const dumTvCap = tvCap? parseInt(tvCap):0
    const dumPriorSaving = priorSaving? parseInt(priorSaving):0

    return formateWithCurrency(dumLatestEquity + dumTvCap + dumPriorSaving )

  }

  return (
    <React.Fragment>
      <Layout title="Start With End In Mind">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="financial_data_heading">
              {company.companyName}
            </Typography>
            <Typography className="financial_data_right_heading">
              NAICS Code: {company.NAICScode}
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 30, width: "100%" }} />

        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography className="hidden_cash_subTitle">
              Start With End In Mind{" "}
              <InfoOutlinedIcon
                onClick={() => handlInfo("sweim")}
                className="infor_icon"
              />
            </Typography>
            <Box style={{ height: 30, width: "100%" }} />

            <Grid width={"100%"} ref={ourPerPlanRef}>
              <CardWrapper border="1px solid #E7E7E7">
                <Typography variant="h6" fontWeight="bold" mb={4}>
                  {" "}
                  Our Plan -{" "}
                  <span style={{ color: "#00bdff" }}>
                    Build Transferable Value:{" "}
                    {currSavingGoal ? formateWithCurrency(currSavingGoal) : 0}
                  </span>
                </Typography>
                <OurPlan
                  yearsUntilExit={yearsUntilExit}
                  monthlyGoal={monthlyGoal}
                  createdAt={createdAt}
                  firstEquity={firstEquity}
                  formateWithCurrency={formateWithCurrency}
                  conversionThousandToK={conversionThousandToK}
                />
              </CardWrapper>
              <Box style={{ height: 30, width: "100%" }} />
              <CardWrapper border="1px solid #E7E7E7">
                <Grid
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={4}>
                    {" "}
                    Our Performance -{" "}
                    <span style={{ color: "#00bdff" }}>
                      Transferable Value Goal:{" "}
                      {handleSaleOfBusiness(latestEquity,transferableValues.reduce(
        (sum, item) => sum + parseInt(removeComma(item.amount)),
        0
      ),  parseInt(priorSaving.replace(/,/g, ""), 10))} / {tvGoal ? formateWithCurrency(parseInt(tvGoal)) : "0"}
                    </span>
                  </Typography>
                  <Grid display="flex" alignItems={"center"}>
                    <Typography
                      mr={2}
                      sx={{
                        textDecoration: "underline",
                        color: "#00bdff",
                        cursor: "pointer",
                      }}
                      onClick={handleShowDetails}
                    >
                      {isShowDetail ? "Hide Details" : "Show Details"}
                    </Typography>
                    <Button onClick={handleAddTv} className="add_tv">
                      Add TV
                    </Button>
                  </Grid>
                </Grid>
                {isShowDetail ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      backgroundColor: "transparent",
                      height: 300,
                      width: 400,
                      marginBottom: "30px",
                    }}
                    ref={tvCapTableRef}
                  >
                    <Table sx={{ minWidth: 200 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "700" }}>Date</TableCell>
                          <TableCell sx={{ fontWeight: "700" }} align="right">
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transferableValues.length ? (
                          transferableValues.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{formateDate(row.date)}</TableCell>
                              <TableCell align="right">
                                {formateWithCurrency(
                                  parseInt(row.amount.replace(/,/g, ""), 10)
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <span style={{ color: "#b0b0b0" }}>
                            No Data Added
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}
                <OurPerformace
                  priorSaving={priorSaving}
                  tvGoal={tvGoal}
                  latestEquity={latestEquity}
                  transferableValues={transferableValues}
                  createdAt={createdAt}
                  yearsUntilExit={yearsUntilExit}
                  formateWithCurrency={formateWithCurrency}
                  handleSaleOfBusiness={handleSaleOfBusiness}
                />
              </CardWrapper>
            </Grid>
            <Box style={{ height: 30, width: "100%" }} />
            <Grid width={"100%"} ref={calculateTranRef}>
            <CardWrapper border="1px solid #E7E7E7" >
              <CalculateTrans
                dream={dream}
                setDream={setDream}
                needInFuture={needInFuture}
                setNeedInFuture={setNeedInFuture}
                yearsUntilExit={yearsUntilExit}
                setYearsUntilExit={setYearsUntilExit}
                priorSaving={priorSaving}
                setPriorSaving={setPriorSaving}
                handleSave={handleSave}
                tvGoal={tvGoal}
                setTvGoal={setTvGoal}
                company={company}
                currSavingGoal={currSavingGoal}
                setCurrSavingGoal={setCurrSavingGoal}
                handleTransferableValueGoal={handleTransferableValueGoal}
                handleCurrSavingGoal={handleCurrSavingGoal}
                handleMonthlyGoal={handleMonthlyGoal}
                formateWithCurrency={formateWithCurrency}
                currency={currency_symbols[company.currency]}
                latestEquity={latestEquity}
              />
            </CardWrapper>
            </Grid>
            
            <Box style={{ height: 30, width: "100%" }} />
            <Grid width={"100%"} ref={estimateValSavingPlanRef}>
            <CardWrapper border="1px solid #E7E7E7">
              <SavingPlan
                currSavingGoal={currSavingGoal}
                monthlyGoal={monthlyGoal}
                yearsUntilExit={yearsUntilExit}
                formateWithCurrency={formateWithCurrency}
                conversionThousandToK={conversionThousandToK}
              />
            </CardWrapper>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <EstimateValue
                latestEquity={latestEquity}
                formateWithCurrency={formateWithCurrency}
              />
            </CardWrapper>
            </Grid>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Accordian
                noteData={startWithEndNotes}
                key1="startWithEndNotes"
                notesRef={startWithEndNotesRef}
                setIsGraphUpdate={setIsGraphUpdate}
              />
            </CardWrapper>
          </Grid>
        </CardWrapper>
      </Layout>

      <Modal
        open={isAddTv}
        onClose={() => setIsAddTv(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Enter TV Details</h2>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Transferable Value
              </Typography>
            </div>
            <CurrencyInput
              className="customize-input1"
              placeholder="Enter Transferable Value"
              value={tvCap}
              color="white"
              type="text"
              onChange={(e) => setTvCap(e.target.value)}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Date
              </Typography>
            </Box>
            <Box
              // sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              id="custom-picker"
              style={{ width: "100%" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={tvCapDate ? dayjs(tvCapDate) : null}
                  style={{
                    borderRadius: "22px",
                  }}
                  className="full-width"
                  // disabled={isEdit}
                  onChange={(newValue) => {
                    setTvCapDate(newValue.$d);
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="login-btn"
                // disabled={companyDetailsLoading}
                onClick={handleTvCapInput}
                id="period-details-btn"
              >
                Submit
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>

      <SnackBar open={openSnackbar} snackType={snackType} msg={message} />
    </React.Fragment>
  );
};
export default StartWithEndInMind;
