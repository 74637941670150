import React, { useState, useEffect } from "react";
import { Layout } from "../../layout";
import "./style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Modal,
  IconButton,
} from "@mui/material";
import { CardWrapper } from "../../components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubscription,
  updateProfile,
  updateProfileName,
} from "../../redux/actions/user";
import { fetchUserById } from "../../redux/actions/user";
import SnackBar from "../../components/snackBar";
import TeamManagment from "./teamManagement";
import { handlInfo } from "../../utils/utilFunctions";
import { clearSnack } from "../../utils/utilFunctions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const CompanyDetail = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);

  const navigate = useNavigate();
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, setCompanyName] = useState();
  // user?.profileName ? user?.profileName : "";

  const [logoPreview, setLogoPreview] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackType, setSnackType] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [cancelSubLoading, setCancelSubLoading] = useState(false);
  const [isUseSameLogo, setIsUseSameLogo] = useState(false);
  const [cancelSubModalOpen, setCancelSubModalOpen] = useState(false);

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  useEffect(() => {
    if (user && Object.keys(user).length) setCompanyName(user?.profileName);
  }, [user]);

  const handleNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleLogoChange = (event) => {
    const selectedLogo = event.target.files[0];

    if (selectedLogo) {
      setCompanyLogo(selectedLogo);
      setLogoPreview(URL.createObjectURL(selectedLogo));
    }
  };

  const handleLogoRemove = () => {
    setCompanyLogo(null);
    setLogoPreview(null);
  };

  // const handleCancelSubscription = async () => {
  //   setCancelSubLoading(true);

  //   const userId = await localStorage.getItem("id");

  //   await dispatch(cancelSubscription(userId, navigate));

  //   setCancelSubLoading(false);

  //   // axios
  //   //   .get(`${BASE_URL}/cancelSubcription/${userId}`)
  //   //   .then((res) => {
  //   //     setCancelSubLoading(false);

  //   //     setOpenSnackBar(true);
  //   //     setSnackType("success");
  //   //     setMessage(res.data.message);

  //   //     localStorage.setItem("paymentStatus", false);
  //   //     localStorage.setItem("cpcp_subscribed", false);

  //   //     setTi
  //   //   })
  //   //   .catch((err) => {
  //   //     setCancelSubLoading(false);

  //   //     setLoading(false);
  //   //     setOpenSnackBar(true);
  //   //     setSnackType();
  //   //     setMessage(err.response.data.message);
  //   //   });
  // };

  const handleSubmit = async () => {
    // Handle form submission, e.g., send data to the server

    if (isUseSameLogo) {
      if (!companyName) {
        dispatch({
          type: "OPEN_SNACK",
          payload: {
            snackType: "error",
            message: "Company Name is required",
          },
        });
        clearSnack(dispatch);
        return;
      }

      const userId = await localStorage.getItem("id");

      const payload = {
        profileName: companyName,
        userId,
      };

      await dispatch(updateProfileName(payload));
      dispatch(fetchUserById({ userId: localStorage.getItem("id") }));
      return;
    }
    if (!companyLogo || !companyName) {
      // setOpenSnackBar(true);
      // setSnackType();
      // setMessage("Company Name and Logo is required");
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Company Name and Logo is required",
        },
      });
      clearSnack(dispatch);
      return;
    }

    const formData = new FormData();

    if (typeof companyLogo === "boolean") {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Company Name and Logo is required",
        },
      });
      clearSnack(dispatch);
      return;
    }

    const userIdd = await localStorage.getItem("id");
    formData.append("profileName", companyName);
    formData.append("userId", userIdd);
    formData.append("logo", companyLogo);

    await dispatch(updateProfile(formData));
    dispatch(fetchUserById({ userId: localStorage.getItem("id") }));
    return;
  };

  const handleClose = () => {
    if (!cancelSubLoading) setCancelSubModalOpen(false);
  };

  return (
    <React.Fragment>
      <Layout title="Company Detail">
        <Grid container xs={12}>
          <Grid item xs={6} style={{ paddingRight: 15 }}>
            <CardWrapper>
              <Typography className="hidden_cash_subTitle">
                Company Details{" "}
                <InfoOutlinedIcon
                  onClick={() => handlInfo("companyDetail")}
                  className="infor_icon"
                />
              </Typography>
              <Box style={{ height: 30, width: "100%" }} />
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "grid", justifyContent: "stretch" }}
                >
                  <Typography style={{ fontSize: 16, marginBottom: 12 }}>
                    Name
                  </Typography>
                  <input
                    value={companyName}
                    onChange={handleNameChange}
                    className="companydetail_inputs"
                    placeholder="Enter Name"
                  />
                </Grid>
                <Box style={{ height: 25, width: "100%" }} />
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 16 }}>Logo</Typography>
                  <Typography
                    style={{
                      fontSize: 10,
                      marginBottom: 12,
                      fontStyle: "italic",
                      color: "gray",
                    }}
                  >
                    Recommended dimensions are 90 pixels in height and 220
                    pixels in width.
                  </Typography>
                  <Grid item xs={12} className="companydetail_logo_input_box">
                    <label htmlFor="upload-company-logo">
                      <Box htmlFor="#hello" className="Choose_File_btn">
                        Choose File
                      </Box>
                    </label>
                    <input
                      id="upload-company-logo"
                      type="file"
                      hidden
                      multiple={false}
                      onChange={handleLogoChange}
                    />
                    {/* <Typography
                      style={{ fontSize: 16, color: "#929292", marginLeft: 20 }}
                    >
                      {companyLogo ? companyLogo?.name : "No file chosen"}
                    </Typography> */}
                  </Grid>
                </Grid>
                {logoPreview && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={logoPreview}
                      alt="Company Logo Preview"
                      style={{ maxWidth: "100px", marginTop: "10px" }}
                    />
                    <IconButton onClick={handleLogoRemove}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
                <Box style={{ height: 15, width: "100%" }} />
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#606060",
                      fontSize: 16,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      checked={isUseSameLogo}
                      onClick={(e) => {
                        setCompanyLogo(true);
                        setIsUseSameLogo(e.target.checked);
                      }}
                      type="checkbox"
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />{" "}
                    Update Without Logo
                  </Typography>
                  <Typography
                    onClick={() =>
                      window.open(
                        "https://billing.stripe.com/p/login/bIY3dt0Qx2kG43m4gg",
                        "_blank"
                      )
                    }
                    style={{
                      fontSize: 16,
                      // color: "#FF5252",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Manage Subscription
                  </Typography>
                </Grid>
                <Box style={{ height: 270, width: "100%" }} />
                <Grid
                  container
                  xs={12}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    onClick={() => navigate("/home")}
                    className="back_btn"
                  >
                    Back
                  </Button>
                  <Button onClick={handleSubmit} className="save_btn">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardWrapper>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15 }}>
            <TeamManagment />
          </Grid>
          {/* <Modal
            open={cancelSubModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <>
              <Box sx={style}>
                <Typography variant="h6" align="center">
                  Are you sure you want to Cancel the Subscription?
                </Typography>

                <Button
                  className="login-btn"
                  sx={{ float: "left" }}
                  // onClick={handleCancelSubscription}
                  disabled={cancelSubLoading}
                >
                  Yes
                  {cancelSubLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "5%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Button>
                <Button
                  className="login-btn"
                  disabled={cancelSubLoading}
                  sx={{ float: "right" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </>
          </Modal> */}
        </Grid>
        <SnackBar
          setOpen={setOpenSnackBar}
          open={openSnackbar}
          snackType={snackType2}
          msg={message2}
        />
      </Layout>
    </React.Fragment>
  );
};
export default CompanyDetail;
